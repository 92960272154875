<template>
  <div class="main">
    <div class="quiz-questions content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item href="">
          <router-link to="/dashboard"><span>Dashboard</span></router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item> <router-link to="/course"><span>Course</span></router-link> </a-breadcrumb-item>
        <a-breadcrumb-item> <router-link :to="'/course/' + courseId + '/chapter'"><span>Chapter</span></router-link> </a-breadcrumb-item>
        <a-breadcrumb-item> <router-link :to="'/course/' + courseId + '/chapter/' + chapterId"><span>Quiz</span></router-link> </a-breadcrumb-item>
        <a-breadcrumb-item> View </a-breadcrumb-item>
      </a-breadcrumb>
      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="title" v-if="quiz">{{quiz.name}}</div>
          <div class="add">
            <a-button type="primary" @click="showAddQuestionModal">
              <PlusOutlined /> Add Question
            </a-button>
            <a-divider type="vertical" />
            <a-button type="primary">
              <router-link :to="{ name: 'View Chapter', params: { courseId, chapterId }}"><ArrowLeftOutlined /> Back</router-link>
            </a-button>
          </div>
        </a-col>
      </a-row>
      <div class="body">
        <ListQuizQuestion :refreshQuesionListKey="refreshQuesionListKey" :question="question" />
      </div>
    </div>
    <AddEditQuestion :showQuestionModel="showQuestionModel" @refreshQuestionList="refreshQuestion" />
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { ArrowLeftOutlined, HomeOutlined, PlusOutlined } from '@ant-design/icons-vue'

import AddEditQuestion from '@/components/agency/quiz/AddEditQuestion.vue'
import ListQuizQuestion from '@/components/agency/quiz/ListQuizQuestion.vue'

import quizService from '../../../services/quiz'

export default defineComponent({
  components: {
    ArrowLeftOutlined,
    HomeOutlined,
    PlusOutlined,
    AddEditQuestion,
    ListQuizQuestion
  },
  setup () {
    const route = useRoute()
    const courseId = ref<string>(route.params.courseId as string)
    const chapterId = ref<string>(route.params.chapterId as string)
    const quizId = ref<string>(route.params.quizId as string)
    const quiz = ref<{ _id: string; name: string; description: string; is_published: boolean; start_time: string; end_time: string; no_of_attempt: number; review: boolean; shuffle: boolean; no_of_questions: number; time_limit: number; course: string; chapter: string }>()
    const question = ref<{ _id: string; code: string; course: string; name: string; type: string; options: []; answers: []; question: string; option: string }>()
    const showQuestionModel = ref<number>(0)
    const refreshQuesionListKey = ref<number>(0)
    const showAddQuestionModal = () => {
      showQuestionModel.value++
    }
    const refreshQuestion = async (data) => {
      question.value = data.data
      refreshQuesionListKey.value++
    }
    const getQuizDetails = async () => {
      const responce = await quizService.getQuizDetails(quizId.value)
      quiz.value = responce.data
    }
    onMounted(() => {
      getQuizDetails()
    })
    return {
      courseId,
      chapterId,
      quizId,
      showQuestionModel,
      showAddQuestionModal,
      refreshQuestion,
      quiz,
      refreshQuesionListKey,
      question
    }
  }
})
</script>
<style lang="scss">
.quiz-questions {
  .header {
    background: #fff;
    padding: 15px;
    margin: 20px 10px 10px;
    border-radius: 10px;
    .title {
      float: left;
      font-family: "TT Norms Pro Medium";
    }
    .add {
      float: right;
      .ant-btn {
        background-color: #38B6FF;
        border-color: #38B6FF;
        border-radius: 25px;
        font-family: "TT Norms Pro Medium";
        a {
          color: #ffffff;
        }
      }
    }
  }
}
</style>
